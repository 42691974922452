* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background-color: black;
}

#root {
  min-height: 80vh;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  opacity: 80%;
}

p {
  font-size: 16px;
}

/* Header */

nav {
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 130px;
  position: relative;
  z-index: 2;
}

nav .logo-wrapper {
  height: 100%;
  display: flex;
}

nav img {
  height: 100%;
}

.mptourism-logo {
  margin-right: 1rem;
}

canvas,
#backgroundsModalWrapper img,
#vbackground,
#output img {
  border-radius: 10px;
}

#backgroundsModalWrapper img,
#output img {
  max-width: 600px;
}

.output-btns {
  display: flex;
  gap: 2rem;
}

/* Main Page */

main {
  position: relative;
  overflow-x: hidden;
}

.videoContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input {
  display: none;
}

.file-upload,
button,
#download {
  cursor: pointer;
  background: #e46a4f;
  border-radius: 3px;
  padding: 1rem 2rem;
  color: white;
  border: none;
  transition: all ease-in-out 200ms;
}

.file-upload:hover,
button:hover,
#download:hover {
  scale: 97%;
}

.backgroundContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div {
  position: relative;
}

.inline-block {
  display: inline-block;
}

.heroVideo {
  width: auto;
  height: 100%;
}

.videoContent,
.backgrounds {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-wrapper,
.output-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 8rem auto;
  gap: 2rem;
  border-radius: 10px;
}

.output-wrapper {
  margin: 8rem auto 2rem auto;
  flex-direction: column;
}

.backgrounds img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.heroVideo {
  width: 100%;
}

.heroImage.landscape {
  display: inline;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.heroImage.portrait {
  display: none;
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 800px) {
  .heroImage.landscape {
    display: none;
  }
  .heroImage.portrait {
    display: inline;
  }
}

.overlay-container {
  position: absolute;
  top: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.overlay-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0000006d;
  backdrop-filter: blur(50px);
  border-radius: 10px;
  padding: 2rem;
  color: #fff;
  line-height: 1.8rem;
  max-width: 800px;
}

.overlay-wrapper button {
  margin-top: 2rem;
  width: 240px;
}

/* Portrait Mode */
@media (orientation: portrait) {
  .heroImage.landscape {
    display: none;
  }

  main {
    overflow: hidden;
    height: 80vh;
  }

  .heroImage.portrait {
    display: inline;
    object-position: top;
    object-fit: cover;
  }

  .overlay-container {
    top: 30%;
  }

  .overlay-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0000006d;
    backdrop-filter: blur(50px);
    border-radius: 10px;
    padding: 2rem;
    color: #fff;
    line-height: 1.8rem;
    margin: 0 1rem;
  }
}

#backgroundsModalWrapper img {
  transition: all ease-in-out 100ms;
}

#backgroundsModalWrapper img:hover {
  transform: scale(95%);
  cursor: pointer;
}

#webcamModalWrapper,
#outputModalWrapper,
#instructionsModal,
#backgroundsModalWrapper {
  position: fixed;
  inset: 0;
  z-index: 2;
  background-color: #252525;
  display: none;
  overflow-y: scroll;
}

#webcamModalWrapper {
  display: block;
}

#instructionsModal,
#backgroundsModalWrapper {
  padding: 5rem 2rem;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-evenly;
  align-items: center;
}

.clickable-image {
  width: 90%;
  height: auto;
}

#instructionsModal p {
  font-size: 36px;
  color: #fff;
  line-height: 4rem;
}

.instructionsModalDiv2 {
  text-align: center;
}

#instructionsModal img {
  width: 150px;
  cursor: pointer;
}

#instructionsModal img:hover {
  opacity: 90%;
}

.output-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 632px) {
  #instructionsModal,
  #backgroundsModalWrapper {
    padding: 8rem 2rem;
  }

  #instructionsModal p {
    font-size: 20px;
  }
}

.modal-wrapper a {
  position: absolute;
  right: 2rem;
  top: 3rem;
  z-index: 3;
}

.modal-wrapper svg {
  font-size: 40px;
  fill: #e46a4f;
}

@media (max-width: 1024px) {
  .heroVideo {
    position: relative;
    height: 100%;
  }
}

.heroVideoPhone {
  display: none;
}

.input-wrapper {
  flex-wrap: wrap-reverse;
}

@media (max-width: 1485px) {
  .output-wrapper {
    flex-direction: column;
  }

  .videoContainer {
    margin-top: 0rem;
    width: 100%;
  }

  .herovideo {
    height: auto;
  }

  .backgroundContainer {
    width: 100%;
    margin-top: 3rem;
  }

  .videoContent {
    width: 90%;
    height: auto;
  }

  .backgrounds {
    width: 90%;
    height: auto;
  }

  .heroVideo {
    display: none;
  }

  .overlay-wrapper p {
    font-size: 16px;
  }

  .heroVideoPhone {
    display: inline-block;
    width: 100%;
  }
}

.backgrounds {
  flex-wrap: wrap;
}

canvas {
  border-radius: 10px;
}

.inputDisplay {
  padding: 1rem;
  display: inline;
}

.landscape {
  display: inline;
}
.portrait {
  display: none;
}

@media (max-width: 800px) {
  .landscape {
    display: none;
  }
  .portrait {
    display: inline-block;
  }
}

/* Footer */

footer {
  background-color: #252525;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.social-wrapper {
  display: flex;
  gap: 1.5rem;
}

.social-wrapper svg {
  font-size: 18px;
  fill: #fff;
  cursor: pointer;
}

.footerLinksAndText-wrapper {
  display: flex;
  gap: 1rem;
}

footer ul {
  display: flex;
  gap: 1.5rem;
}

footer a,
footer span {
  color: #fff;
}

@media only screen and (max-width: 800px) {
  #backgroundsModalWrapper img,
  #output img {
    max-width: 90%;
  }

  footer {
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }

  .footerLinksAndText-wrapper {
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }

  nav {
    flex-wrap: wrap-reverse;
    height: auto;
    justify-content: center;
    gap: 2rem;
  }

  nav .logo-wrapper {
    height: 80px;
  }
}

@media (max-width: 460px) {
  footer ul {
    border-right: none;
    margin: 0;
    padding: 0;
  }
}
